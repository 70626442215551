import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Sticky from 'react-stickynode'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import { Sidebar } from '../components/Sidebar'
import { StyledDocs } from '../assets/styles/templates/docs.styles'

const DocsTemplate = ({
  data: {
    mdx: {
      body,
      tableOfContents,
      frontmatter,
      frontmatter: {
        title,
        colors,
        image: { alt, url },
      },
    },
  },
}) => {
  const shortcodes = { Link, StaticImage }
  const image = getImage(url)
  return (
    <Layout>
      <StyledDocs>
        <Sidebar />
        <main>
          <header
            style={{
              background: `linear-gradient(to right, ${colors[0]},  ${colors[1]})`,
            }}
          >
            <h1>{title}</h1>
            <GatsbyImage alt={alt} image={image} />
          </header>
          <div>
            <div>
              <MDXProvider components={shortcodes}>
                <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
              </MDXProvider>
            </div>
            {tableOfContents.items && (
              <Sticky top={50}>
                <aside>
                  <h2>On this page</h2>
                  <ul>
                    {tableOfContents?.items?.map(
                      ({ url: linkUrl, title: linkTitle, items }) => (
                        <li key={linkTitle}>
                          <Link to={linkUrl}>{linkTitle}</Link>
                          {items && (
                            <ul>
                              {items?.map(
                                ({ url: subLinkUrl, title: subLinkTitle }) => (
                                  <li key={subLinkTitle}>
                                    <Link to={subLinkUrl}>{subLinkTitle}</Link>
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                </aside>
              </Sticky>
            )}
          </div>
        </main>
      </StyledDocs>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        colors
        image {
          alt
          url {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 80
                height: 300
              )
            }
          }
        }
      }
    }
  }
`

DocsTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string.isRequired,
      tableOfContents: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
              PropTypes.shape({
                url: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
              })
            ),
          })
        ),
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        colors: PropTypes.arrayOf(PropTypes.string),
        image: PropTypes.shape({
          alt: PropTypes.string.isRequired,
          url: PropTypes.object,
        }),
      }),
    }),
  }),
}

export default DocsTemplate
