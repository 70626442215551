import styled from 'styled-components'

export const StyledSidebar = styled.aside`
  min-height: 100vh;
  background: var(--color__1);
  color: #fff;
  padding: 20px;

  input {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    border: none;
    padding: 8px 15px;
    outline: 0;
    color: #fff;
  }

  a {
    color: #fff;
  }

  nav > ul {
    margin: 3rem 0 0;
    padding: 0;

    > li {
      display: block;
      margin: 0 0 1rem;

      > a {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
`
