import styled from 'styled-components'

export const StyledDocs = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 50px;

  header {
    margin: 0 0 4rem -50px;
    height: 300px;
    position: relative;

    .gatsby-image-wrapper {
      height: 100%;
      opacity: 0.4;
    }

    h1 {
      position: absolute;
      margin: 0 0 0 50px;
      color: #fff;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      font-size: var(--font-size__h0);
    }

    + div {
      padding: 0 50px 100px 0;
      display: grid;
      grid-template-columns: 1fr 250px;
      gap: 50px;

      aside {
        h2 {
          margin-top: 0;
          font-size: var(--font-size__h4);
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
          font-weight: 500;
          font-size: var(--font-size__small);
          margin: 0;
          padding: 0;

          ul {
            margin-top: 10px;
            padding-left: 10px;

            li::before {
              content: '- ';
            }
          }
        }

        li {
          display: block;
        }

        a {
          color: var(--color__1);
        }
      }
    }
  }
`
