import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StyledSidebar } from './Sidebar.styles'

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        edges {
          node {
            id
            frontmatter {
              title
            }
            slug
          }
        }
      }
    }
  `)
  return (
    <StyledSidebar>
      {/* <input type="text" placeholder="Search documentation..." /> */}
      <nav>
        <ul>
          {data.allMdx.edges.map(({ node }) => {
            const {
              id,
              slug,
              frontmatter: { title },
            } = node
            return (
              <li key={id}>
                <Link to={`/docs/${slug}`}>{title}</Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </StyledSidebar>
  )
}

export default Sidebar
